import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { firstValueFrom } from 'rxjs'

/**
 * Runtime configuration interface.
 * It contains all the properties that are loaded from the config.json file and
 * that could change depending on the env where the application is deployed.
 */
export interface RuntimeConfig {
   environment: string
   linkingToolUrl: string
   pmpToolUrl: string
   budgetToolUrl: string
}

/**
 * Service that provides the runtime configuration of the application.
 * It loads the configuration from a remote endpoint and provides it to the application.
 */
@Injectable({
   providedIn: 'root',
})
export class ConfigService {
   private readonly RUNTIME_CONFIG_PATH = 'assets/configs/properties.json'

   private config: RuntimeConfig = {
      environment: 'NONE',
      linkingToolUrl: '',
      pmpToolUrl: '',
      budgetToolUrl: '',
   }

   constructor(private httpClient: HttpClient) {}

   /**
    * Initializes the dynamic configuration from the config.json file.
    * @returns {Promise<RuntimeConfig>} The dynamic configuration.
    */
   initRuntimeConfig(): Promise<RuntimeConfig> {
      return firstValueFrom(this.httpClient.get<RuntimeConfig>(this.RUNTIME_CONFIG_PATH)).then((dc) => {
         if (!dc) {
            throw new Error('Runtime configuration not found')
         }
         this.config = dc
         console.log('Runtime Configuration loaded for env: ', this.environment)
         return dc
      })
   }

   get environment(): string {
      return this.config.environment
   }

   get linkingToolUrl(): string {
      return this.config.linkingToolUrl
   }

   get pmpToolUrl(): string {
      return this.config.pmpToolUrl
   }

   get budgetToolUrl(): string {
      return this.config.budgetToolUrl
   }
}
